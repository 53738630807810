



































import { Vue, Component } from "vue-property-decorator";
import TablaGeneralFinalComponent from "@/components/General/TablaGeneralFinal.vue";
import Formulario from "@/components/Maestro/Ktb/Formulario.vue";
import {
  Defecto,
  EditarEstadoDefecto,
  ObtenerDefecto,
} from "@/entidades/Maestro/Ktb/Defecto";
import { Action } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
@Component({
  components: {
    Tabla: TablaGeneralFinalComponent,
    Formulario: Formulario,
  },
})
export default class DefectoView extends Vue {
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Descripcion", sortable: true, value: "descripcion" },
    { text: "Action", sortable: false, value: "actions" },
    { text: "Estado", sortable: true, value: "estado" },
  ];
  modal: boolean = false;
  currentComponent: string = "";
  listado: Array<Defecto> = [];
  modelo: Defecto | undefined = undefined;
  actions: number = 1;
  async editarEstado(defecto: Defecto) {
    try {
      this.changeLoading(new Loading(true, "Editando informacion..."));
      let rsp = await EditarEstadoDefecto(defecto);
      if (rsp.isSuccess == true) {
        this.limpiar();
      } else {
        this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 3000));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.changeLoading(new Loading(false, "Cargando informacion..."));
    }
  }

  async abrilModal() {
    this.actions = 1;
    this.currentComponent = "Formulario";
    this.modal = true;
  }
  editarModal(defecto: Defecto) {
    this.currentComponent = "Formulario";
    this.actions = 2;
    this.modelo = defecto;
    this.modal = true;
  }

  async Obtener() {
    try {
      this.changeLoading(new Loading(true, "Cargando informacion..."));
      let rsp = await ObtenerDefecto();
      if (rsp.isSuccess == true) {
        this.listado = rsp.isResult;
      } else {
        this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 3000));
      }
    } catch (error) {
        //@ts-ignore
      this.changeAlerta(new Alerta(error, true, "danger", 3000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  limpiar() {
    this.Obtener();
    this.modelo = undefined;
    this.currentComponent = "";
    this.modal = false;
  }
  get getPropiedades() {
    if (this.currentComponent == "Formulario") {
      return { action: this.actions, modelo: this.modelo };
    }
  }
  mounted() {
    this.Obtener();
  }
}

import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import {mainAxios} from '@/plugins/axios';
class Defecto
{
    defectoId?:number = 0;
    descripcion?:string ="";
    estado?:boolean = false;

    constructor(_defectoId?:number,_descripcion?:string,_estado?:boolean)
    {
        this.defectoId = _defectoId;
        this.descripcion  =_descripcion;
        this.estado = _estado;
    }
}

async function  GuardarDefecto(defecto:Defecto):Promise<ResponseGenerico>
{
    return await Save<Defecto>("Defecto/Guardar",defecto,mainAxios);
} 

async function  EditarDefecto(defecto:Defecto):Promise<ResponseGenerico>
{
    return await Editar<Defecto>("Defecto/Actualizar",defecto,mainAxios);
} 

async function  EditarEstadoDefecto(defecto:Defecto):Promise<ResponseGenerico>
{
    return await Editar<Defecto>("Defecto/CambiarEstado",defecto,mainAxios);
} 

async function ObtenerDefecto()
{
    return await Obtener<Defecto>("Defecto/Obtener",mainAxios);
}


async function ObtenerSelectDefecto()
{
    return await Obtener<Defecto>("Defecto/ObtenerSelect",mainAxios);
}

export 
{
    Defecto,
    GuardarDefecto,
    EditarDefecto,
    EditarEstadoDefecto,
    ObtenerDefecto,
    ObtenerSelectDefecto
}
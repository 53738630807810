
















































import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "TablaGeneralFinalComponent",
})
export default class TablaGeneralFinalComponent extends Vue {
  @Prop({ type: Array, required: true })
  headers!: Array<any>;

  @Prop({ type: String, required: false })
  title!: string;

  @Prop({ type: Array, required: true })
  items!: Array<any>;

  @Prop({ type: Boolean, required: false, default: true })
  busqueda!: boolean;

  @Prop({ type: String, required: false }) textoEstado!: string;

  //variables
  search: string = "";

  UpdateTitle(estado: boolean | string): string {
    console.log('valor que llega en el estado',this.textoEstado);
    let texto: string = "";
    if (this.textoEstado !== "" && this.textoEstado != undefined) {
      if (estado == "A") {
        texto = "ABIERTA";
      } else {
        texto = "CERRADA";
      }
    } else {
      if (estado == true) {
        texto = "ACTIVO";
      } else {
        texto = "INACTIVO";
      }
    }

    return texto;
  }
  UpdateColor(estado: boolean | string): string {
    let color: string = "primary";
    if (estado == true) {
      color = "primary";
    } else {
      color = "secondary";
    }
    return color;
  }

  get UpdateItems() {
    if (this.items.length > 0) {
      return this.items;
    } else {
      return [];
    }
  }
}
